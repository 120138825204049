import React, {Component} from 'react';
import firebase from "./firebase";
import styles from "./Chat.module.css";
import {Box, Button, Card, CardActions, CardContent, CircularProgress, TextField} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

export default class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
            chatFieldText: "",
            chatId: "",
            loadContainer: true,
        }
    }
    async componentDidMount() {
        if (localStorage.getItem("userId")) {
            if (this.props.sender === "buyer") {
                await fetch("https://for-regulars.herokuapp.com/get_chat?" +
                    "seller_id=" + this.props.sellerId +
                    "&buyer_id=" + localStorage.getItem("userId")
                    , {
                        method: "GET",
                    }).then(response => response.json())
                    .then(async (result) => {
                        this.setState({
                            chats: result["chat"],
                            chatId: result["chat_id"]
                        }, () => {
                            this.chatListener()
                        })
                    });
            }
        }
    }
    componentDidUpdate() {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }
    }
    chatListener() {
        if (this.state.chatId) {
            let chatRef = firebase.database().ref(this.state.chatId);
            chatRef.on('value', (snapshot) => {
                this.updateChat(snapshot.val())
            });
        }
    }

    updateChat = (value) => {
        let list_messages = []
        Object.keys(value).forEach(val => {
            let new_tup = [value[val]["sender"], value[val]["content"], value[val]["time"]]
            list_messages.push(new_tup)
        })
        list_messages = list_messages.sort(this.compare)
        this.setState({
            chats: list_messages,
        })
    }
    compare(a, b) {
        let a_time = new Date(a[2])
        let b_time = new Date(b[2])
        if (a_time > b_time) {
            return 1;
        } else {
            return -1;
        }
    }

    handleChange = (e) => {
        this.setState({
            chatFieldText: e.target.value,
        })
    }
    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.submitChat()
        }
    }
    submitChat = async () => {
        if (this.state.chatId === "") {
            await fetch("https://for-regulars.herokuapp.com/create_new_chat?" +
                "seller_id=" + this.props.sellerId +
                "&buyer_id=" + localStorage.getItem("userId") +
                "&buyer_name=" + localStorage.getItem("userName")
                , {
                    method: "GET",
                }).then(response => response.text())
                .then(async (result) => {
                    if (result !== "") {
                        await this.setState({
                            chatId: result
                        })
                    }
                });
        }

        await fetch("https://for-regulars.herokuapp.com/new_chat_message?" +
            "chat_id=" + this.state.chatId +
            "&content=" + this.state.chatFieldText +
            "&sender=" + this.props.sender
            , {
                method: "GET",
            }).then(response => response.text())
            .then(async (result) => {
                if (result !== "success") {
                    alert("Error")
                } else {
                    this.chatListener()
                }
            });
        this.setState({
            chatFieldText: ""
        })
    }
    setLoadContainer = async (chatId) => {
        this.setState({
            loadContainer: false,
        })
        await fetch("https://for-regulars.herokuapp.com/get_chat_with_id?" +
            "chat_id=" + chatId
            , {
                method: "GET",
            }).then(response => response.json())
            .then(async (result) => {
                this.setState({
                    chats: result,
                    chatId: chatId
                }, () => {
                    this.chatListener()
                })
            });
    }

    render() {
        console.log()
        return (
            <div>
                {localStorage.getItem("userId") == null ?
                    <div style={{textAlign: "center"}}><span style={{color: "red"}}>Please sign in to chat with seller</span></div>
                :
                    <div>
                        {(this.props.sender === "seller" && this.state.loadContainer) ?
                            <ChatContainer setLoadContainer={this.setLoadContainer} />
                            :
                            <div>
                                <div className={styles.chatContainer}>
                                    <div className={styles.allChatMessages}>
                                        {this.state.chats.map((chat_obj) => {
                                            let side = "left"
                                            let color = "rgb(53,170,229)"
                                            if (chat_obj[0] === this.props.sender) {
                                                side = "right"
                                                color = "rgb(169,169,169)"
                                            }
                                            return (
                                                <div className={styles.chatBubbleContainer}>
                                                    <div className={styles.chatSpacer} />
                                                    <div className={styles.chatBubble} style={{float: side, backgroundColor: color}}>
                                                        <span className={styles.chatText}>
                                                            {chat_obj[1]}
                                                        </span>
                                                    </div>
                                                    <div className={styles.chatSpacer} />
                                                </div>
                                            )
                                        })}
                                        <div style={{ float:"left", clear: "both" }}
                                             ref={(el) => { this.messagesEnd = el; }}>
                                        </div>
                                    </div>
                                    <br />
                                    <div style={{width: "100%"}}>
                                        <TextField className={styles.chatTextField} onChange={this.handleChange} onKeyDown={this.handleKeyDown} value={this.state.chatFieldText} label="Chat with Seller" variant="outlined" />
                                        <Button endIcon={<SendIcon />} variant="contained" color="primary" onClick={this.submitChat} className={styles.sendChatButton}>Send</Button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>

        );
    }
}

export class ChatContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allChats: {},
            chatData: {},
            loading: true,
        };
    }

    async componentDidMount() {
        let allChats = {}
        await fetch("https://for-regulars.herokuapp.com/get_all_chat_ids?" +
            "seller_id=" + localStorage.getItem("userId")
            , {
                method: "GET",
            }).then(response => response.json())
            .then(async (result) => {
                allChats = result
            });

        let count = 0
        let chatData = {}
        for (const buyer_id of Object.keys(allChats)) {
            if (count < 3) {
                await fetch("https://for-regulars.herokuapp.com/get_preview_chat?" +
                    "seller_id=" + localStorage.getItem("userId") +
                    "&buyer_id=" + buyer_id
                    , {
                        method: "GET",
                    }).then(response => response.json())
                    .then(async (result) => {
                        chatData[buyer_id] = result
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
                count += 1
            } else {
                break;
            }
        }
        this.setState({allChats, chatData, loading: false})
    }

    chatClicked = (chat_id) => {
        this.props.setLoadContainer(chat_id)
    }

    render() {
        return (
            <div className={styles.chatContainer}>
                {this.state.loading ?
                    <CircularProgress size={200} color={"inherit"} className={styles.loadingCircle} />
                :
                    null
                }
                {Object.keys(this.state.allChats).map((user_id) => {
                    return(
                        <Card  variant="outlined" className={styles.chatCard}>
                            <Box border={1} borderColor={"rgb(104, 200, 142)"}>
                                <div className={styles.cardHeader}>
                                    <div className={styles.chatPerson}>
                                        {this.state.allChats[user_id]["name"]}
                                    </div>
                                </div>
                                <CardContent className={styles.chatCardContent}>
                                    {user_id in this.state.chatData ? Object.keys(this.state.chatData[user_id]).map((message_id) => {
                                        let side = "left"
                                        let color = "rgb(53,170,229)"
                                        if (this.state.chatData[user_id][message_id][0] === "seller") {
                                            side = "right"
                                            color = "rgb(169,169,169)"
                                        }
                                        return (
                                            <div className={styles.chatBubbleContainer}>
                                                <div className={styles.chatSpacer} />
                                                    <div className={styles.chatBubblePreview} style={{float: side, backgroundColor: color}}>
                                                        <span className={styles.chatText}>
                                                            {this.state.chatData[user_id][message_id][1]}
                                                        </span>
                                                    </div>
                                                <div className={styles.chatSpacer} />
                                            </div>
                                        )
                                    }): null}
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={this.chatClicked.bind(this, this.state.allChats[user_id]["chat_id"])}>Go to Chat</Button>
                                </CardActions>
                            </Box>
                        </Card>
                    )
                })}
            </div>
        )
    }
}