import firebase from './firebase.js'
import React, {Component} from 'react';
import styles from './Profile.module.css';
import appStyles from './App.module.css';
import listingStyles from './Listing.module.css';
import {Button, TextField, Grid, CircularProgress} from "@material-ui/core";

import Chat from './Chat';
import Sidebar from './Sidebar';
import Offer from './Offer';
import { uuidv4 } from './Utils';

export default class Storefront extends Component {

    constructor(props) {
        super(props);
        this.state = {
            has_storefront: false,
            creatingStorefront: false,
            storefrontName: '',
            storefrontAddress: '',
            storefrontZipcode: '',
            stripeId: '',
            storefrontDescription: '',
            listingId: null,
            currentPage: 'edit',
            offers: {},
            creatingOffer: false,
            offerName: '',
            offerDescription: '',
            offerPrice: '',
            offerInterval: 'day',
            offerIntervalCount: '1',
            validStripe: true,
            loading: true,
        }

        this.storefrontImageInput = React.createRef();
        this.offerImageInput = React.createRef();

        this.storeFrontCreateOrEdit = this.storeFrontCreateOrEdit.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
    }

    async componentDidMount() {
        document.body.style = 'background: #F4FAF6;';

        if (localStorage.getItem("userId") == null) {
            // user not logged in
            return;
        }

        const userDataDoc = await firebase
            .firestore()
            .collection('userData')
            .doc(localStorage.getItem("userId"))
            .get();
        
        const listingId = userDataDoc.get("listingId")
        const listingDoc = await firebase
            .firestore()
            .collection('listings')
            .doc(listingId)
            .get();

        let validStripe = false
        await fetch("https://for-regulars.herokuapp.com/valid-account?" +
            "account_id=" + listingDoc.get("stripe_account_id")
            , {
                method: "GET",
            })
            .then(response => response.json())
            .then(result => {
                validStripe = result
            })

        this.setState({
            has_storefront: userDataDoc.get("has_storefront"),
            storefrontName: listingDoc.get("name"),
            storefrontAddress: listingDoc.get("address"),
            storefrontZipcode: listingDoc.get("zipcode"),
            stripe_id: listingDoc.get("stripe_account_id"),
            validStripe: validStripe,
            storefrontDescription: listingDoc.get("description"),
            loading: false,
            listingId
        }, async () => {
            await this.getLatestOffers()
            await this.getLatestCustomers()
        })
    }

    getLatestOffers = async () => {
        if (this.state.listingId != null) {
            const listingOffers = await firebase
                .firestore()
                .collection('listings')
                .doc(this.state.listingId)
                .collection("offers")
                .get();
            let offers = {};
            if (listingOffers.docs) {
                listingOffers.docs.forEach((doc) => {
                    offers[doc.id] = doc.data();
                });
            }
            this.setState({offers})
        }
    }

    getLatestCustomers = async () => {
        if (this.state.listingId != null) {
            const userData = await firebase
                .firestore()
                .collection('userData')
                .get();

            if (userData.docs) {
                let customers = {};

                userData.docs.forEach((doc) => {
                    const data = doc.data();
                    if ('subscriptions' in data) {
                        // console.log(doc.id, " => ", doc.data());
                        // const subs = data['subscriptions'];
                        if (this.state.listingId in data['subscriptions']) {
                            const subs = data['subscriptions'][this.state.listingId];
                            console.log('Subs: ', subs);

                            customers[doc.id] = {
                                name: data['name'],
                                email: data['email'],
                                phone: data['phone'],
                                address: data['address'],
                                subs: subs,
                            };
                        }
                        
                    }
                });

                this.setState({customers})
            }
        }
    }
    
    createAccount = async () => {
        await fetch("https://for-regulars.herokuapp.com/create-account?" +
            "listing_id=" + this.state.listingId
            , {
                method: "GET",
            }).then(response => response.json())
            .then(async (result) => {
                console.log(result)
                window.location.href = result["url"]
            });
    }

    editClicked = () => {
        this.setState({currentPage: 'edit'})
    }
    offerClicked = () => {
        this.setState({currentPage: 'offer'})
    }
    chatClicked = () => {
        this.setState({currentPage: 'chat'})
    }
    customersClicked = () => {
        this.setState({currentPage: 'customers'})
    }
    createStorefront = () => {
        this.setState({
            creatingStorefront: true,
        })
    }
    createOffer = () => {
        this.setState({
            creatingOffer: true,
        })
    }
    changeStorefrontName = (e) => {
        this.setState({
            storefrontName: e.target.value
        })
    }
    changeStorefrontAddress = (e) => {
        this.setState({
            storefrontAddress: e.target.value
        })
    }
    changeStorefrontZipcode = (e) => {
        this.setState({
            storefrontZipcode: e.target.value
        })
    }
    changeStorefrontDescription = (e) => {
        this.setState({
            storefrontDescription: e.target.value
        })
    }
    changeOfferName = (e) => {
        this.setState({
            offerName: e.target.value
        })
    }
    changeOfferDescription = (e) => {
        this.setState({
            offerDescription: e.target.value
        })
    }

    uploadImage = (image) => {
        const images = image.current.files;
        let imageId = "";
        if (images.length !== 0) {
            imageId = uuidv4();
            
            const listingsImages = firebase
                .storage()
                .ref('listing_images/' + imageId);

            // Upload listing image to Firebase Storage
            listingsImages.put(images[0]).then(function(snapshot) {
                console.log('Uploaded storefront image to Firebase Storage!');
            });
        }
        return imageId
    }

    createStorefrontFinal = async () => {
        let listingId = this.state.listingId;
        if (listingId == null) {
            listingId = uuidv4()
        }
        const userDataDoc = firebase
            .firestore()
            .collection('userData')
            .doc(localStorage.getItem("userId"));
        userDataDoc.update({
            has_storefront: true,
            listingId,
        })
        this.setState({
            has_storefront: true,
            creatingStorefront: false,
            listingId: listingId
        })

        const listingsDoc = firebase
            .firestore()
            .collection('listings')
            .doc(listingId);
        const listingsDocGet = await listingsDoc.get();

        const imageId = this.uploadImage(this.storefrontImageInput);
        
        const listingData = {
            name: this.state.storefrontName,
            address: this.state.storefrontAddress,
            zipcode: this.state.storefrontZipcode,
            description: this.state.storefrontDescription,
            image: imageId,
            seller_id: localStorage.getItem("userId"),
        }
        if (listingsDocGet.exists) {
            listingsDoc.update(listingData)
        } else {
            listingsDoc.set(listingData)
        }
        
    }

    createOfferFinal = async () => {
        const imageId = this.uploadImage(this.offerImageInput);

        const listingId = this.state.listingId;
        await firebase
            .firestore()
            .collection('listings')
            .doc(listingId)
            .collection("offers")
            .add({
                name: this.state.offerName,
                description: this.state.offerDescription,
                price: this.state.offerPrice,
                interval: this.state.offerInterval,
                intervalCount: this.state.offerIntervalCount,
                image: imageId,
            });
        this.setState({
            offerName: '',
            offerDescription: '',
            offerPrice: '',
            offerInterval: '',
            creatingOffer: false,
        }, async () => {
            this.getLatestOffers()
        })
    }

    deleteOffer = async (offerId) => {
        const listingId = this.state.listingId;

        await firebase
            .firestore()
            .collection('listings')
            .doc(listingId)
            .collection("offers")
            .doc(offerId)
            .delete();
        
        this.getLatestOffers();
    }

    storefrontForm = (props) => {

        return (
            <form className={styles.createForm}>
                <TextField value={this.state.storefrontName} onChange={this.changeStorefrontName}
                            id="outlined-basic" label="Storefront Name" variant="outlined"
                            className={styles.createField}/>
                <br/><br/>
                <TextField value={this.state.storefrontAddress}
                            onChange={this.changeStorefrontAddress} id="outlined-basic"
                            label="Storefront Address" variant="outlined"
                            className={styles.createField}/>
                <br/><br/>
                <TextField value={this.state.storefrontZipcode}
                            onChange={this.changeStorefrontZipcode} id="outlined-basic"
                            label="Zipcode" variant="outlined"
                            className={styles.createField}/>
                <br/><br/>
                <TextField value={this.state.storefrontDescription}
                            onChange={this.changeStorefrontDescription} id="outlined-basic"
                            label="Storefront Description" variant="outlined"
                            className={styles.createField}/>
                <br/><br/>
                <input type="file" ref={this.storefrontImageInput} />
                <br/><br/>
                <Button variant="contained" onClick={this.createStorefrontFinal}>{ props }</Button>

                <br/><br/>
                {!this.state.stripe_id ?
                    <Button variant="raised" onClick={this.createAccount} className={styles.stripeButton}><img src={"./Stripe.png"} alt={''} width={200} /></Button>
                    :
                    null
                }
                {this.state.stripe_id && !this.state.validStripe ?
                    <span style={{color: "red"}}>Your Stripe Account is not complete, please go to the Stripe dashboard and finish the setup</span>
                    :
                    null
                }

            </form>
        )
    }

    currentTab = () => {
        if (this.state.currentPage === "edit") {
            return this.storefrontForm("Update");
        } else if (this.state.currentPage === "offer") {
            return (
                <div>
                    {!this.state.creatingOffer ?
                        <div className={styles.newListing}>
                            <Button onClick={this.createOffer} className={styles.offerButton}>Add Offer</Button>
                        </div>
                        :
                        <div>
                            <form className={styles.createForm}>
                                <TextField value={this.state.offerName} onChange={this.changeOfferName}
                                        id="outlined-basic" label="Offer Name" variant="outlined"
                                        className={styles.createField}/>
                                <br/><br/>
                                <TextField value={this.state.offerDescription}
                                        onChange={this.changeOfferDescription} id="outlined-basic"
                                        label="Offer Description" variant="outlined"
                                        className={styles.createField}/>
                                <br/><br/>
                                <TextField value={this.state.offerPrice}
                                        onChange={(e) => this.setState({offerPrice: e.target.value})}
                                        id="outlined-basic"
                                        label="Price ($)" variant="outlined"
                                        className={styles.createField}/>
                                <br/><br/>

                                Every
                                <select value={this.state.offerIntervalCount} onChange={(e) => this.setState({offerIntervalCount: e.target.value})}>
                                    <option selected value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>

                                <select value={this.state.offerInterval} onChange={(e) => this.setState({offerInterval: e.target.value})}>
                                    <option selected value="day">day</option>
                                    <option value="week">week</option>
                                    <option value="month">month</option>
                                    <option value="year">year</option>
                                </select>
                                (s)
                                <br/><br/>

                                <input type="file" ref={this.offerImageInput} />
                                <br/><br/>
                                <Button variant="contained"
                                        onClick={this.createOfferFinal}>Create</Button>
                            </form>
                        </div>
                    }
                    {Object.keys(this.state.offers).map((id) => {
                        return (
                            <Offer 
                                seller={true}
                                data = {this.state.offers[id]}
                                name = {this.state.offers[id]["name"]}
                                description = {this.state.offers[id]["description"]}
                                deleteOffer = {() => this.deleteOffer(id)}
                            />
                        );
                    })}
                </div>
            )
        } else if (this.state.currentPage === "chat") {
            return <Chat sellerId={this.state.sellerId} sender={"seller"} />
        } else if (this.state.currentPage === "customers") {
            if (this.state.customers == null ||
                (this.state.customers && Object.keys(this.state.customers).length === 0)) {
                return <div>You currently have no customers subscribed.</div>
            }
            return (
                <div>
                    {Object.keys(this.state.customers).map((id) => {
                        console.log(this.state.customers[id])
                        return (
                            <Grid container spacing={6}>
                                    <Grid item md={11}>
                                        <Grid container direction="row" spacing ={3}>
                                            <Grid item>
                                                <div className={styles.subContainer}>
                                                    <span className={styles.subscriberText}>
                                                        {this.state.customers[id]["name"]}
                                                        <br/>
                                                        {this.state.customers[id]["email"]}
                                                        <br/>
                                                        {this.state.customers[id]["phone"]}                                                   
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className={styles.offerContainer}>
                                                    {Object.keys(this.state.customers[id]["subs"]).map((key) => {
                                                        const offer = this.state.customers[id]["subs"][key];
                                                        return (
                                                            <div className={styles.subscriberSecondaryText}>
                                                                - {offer["offerName"]}
                                                                <br/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className={styles.offerContainer}>
                                                    {Object.keys(this.state.customers[id]["subs"]).map((key) => {
                                                        // const offer = this.state.customers[id]["subs"][key];
                                                        return (
                                                            <div className={styles.subscriberSecondaryText}>
                                                                {"weekly"}
                                                                <br/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className={styles.offerContainer}>
                                                    {Object.keys(this.state.customers[id]["subs"]).map((key) => {
                                                        // const offer = this.state.customers[id]["subs"][key];
                                                        return (
                                                            <div className={styles.subscriberSecondaryText}>
                                                                {"Wednesday @ 9:30"}
                                                                <br/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            </Grid>
                        );
                    })}
                </div>
            );
        }
        return <div>No Tab Selected</div>
    }

    storeFrontCreateOrEdit = () => {
        let editColor = '#F4FAF6';
        let offerColor = '#F4FAF6';
        let chatColor = '#F4FAF6';
        let customersColor = '#F4FAF6';
        if (this.state.currentPage === "edit") {
            editColor = "#97d8ae"
            offerColor = "#b1edbe"
            chatColor = "#b1edbe"
            customersColor = "#b1edbe"
        } else if (this.state.currentPage === "offer") {
            editColor = "#b1edbe"
            offerColor = "#97d8ae"
            chatColor = "#b1edbe"
            customersColor = "#b1edbe"
        } else if (this.state.currentPage === "chat") {
            editColor = "#b1edbe"
            offerColor = "#b1edbe"
            chatColor = "#97d8ae"
            customersColor = "#b1edbe"  
        } else if (this.state.currentPage === "customers") {
            editColor = "#b1edbe"
            offerColor = "#b1edbe"
            chatColor = "#b1edbe"
            customersColor = "#97d8ae"
        }

        if (!this.state.has_storefront) {
            if (!this.state.creatingStorefront) {
                return (
                    <div className={styles.newStore}>
                        <Button onClick={this.createStorefront} className={styles.offerButton}>Create Storefront</Button>
                    </div>
                )
            } else {
                return this.storefrontForm("Create");
            }
        } else {
            return (
                <div>
                    <div className={listingStyles.options}>
                        <div className={styles.innerOption} style={{backgroundColor: editColor}}
                            onClick={this.editClicked}>
                            <span className={listingStyles.optionText}>Edit</span>
                        </div>
                        <div className={styles.innerOption} style={{backgroundColor: offerColor}}
                            onClick={this.offerClicked}>
                            <span className={listingStyles.optionText}>My Offers</span>
                        </div>
                        <div className={styles.innerOption} style={{backgroundColor: chatColor}}
                            onClick={this.chatClicked}>
                            <span className={listingStyles.optionText}>My Chats</span>
                        </div>
                        <div className={styles.innerOption} style={{backgroundColor: customersColor}}
                            onClick={this.customersClicked}>
                            <span className={listingStyles.optionText}>My Customers</span>
                        </div>
                    </div>

                    <div className={styles.tab}>
                        { this.currentTab() }
                    </div>
                    
                </div>
            )
        }
    }

    render() {
        return (
            <div className={appStyles.appContainer}>
                <Sidebar />
                <div className={appStyles.app}>
                    <div className={listingStyles.headerContainer}>
                        <div className={listingStyles.headerFirstLine}>
                            <Button href={"../"} className={listingStyles.backButton}>Back</Button>
                            <h1 className={listingStyles.companyText}>forRegulars</h1>
                        </div>
                        <h1 className={listingStyles.headerText}>{this.state.storefrontName} Storefront</h1>
                    </div>

                    {this.state.loading ?
                        <CircularProgress size={200} color={"inherit"} className={styles.loadingCircle} />
                        :
                        null
                    }
                    { localStorage.getItem("userId") === null ?
                        <div> User must be signed in to access Storefront. </div>
                        :
                        <div>{!this.state.loading ? this.storeFrontCreateOrEdit() : null}</div>
                    }
                </div>
            </div>
            
        );
    }
}

