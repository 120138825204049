import firebase from 'firebase'
var config = {
    apiKey: "AIzaSyDs133m2puYsIJ4seJtq9zKhTXYR2YZlo0",
    authDomain: "subscrybe-8db97.firebaseapp.com",
    projectId: "subscrybe-8db97",
    storageBucket: "subscrybe-8db97.appspot.com",
    messagingSenderId: "353041745396",
    appId: "1:353041745396:web:708ad5ba54abfc8142a5a1",
    measurementId: "G-RHEH2P80C5"
};
firebase.initializeApp(config);
export default firebase;