import firebase from './firebase.js'
import React, {Component} from 'react';
import styles from './Profile.module.css';
import appStyles from './App.module.css';
import listingStyles from './Listing.module.css';
import { Button, Grid } from "@material-ui/core";

import Sidebar from './Sidebar';

export default class Subscriptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            purchasedSubscriptions: {}
        }
    }

    async componentDidMount() {
        document.body.style = 'background: #F4FAF6;';

        if (localStorage.getItem("userId") == null) {
            // user not logged in
            return;
        }

        const userDataDoc = await firebase
            .firestore()
            .collection('userData')
            .doc(localStorage.getItem("userId"))
            .get();
        this.setState({
            purchasedSubscriptions: userDataDoc.get("subscriptions")
        });
    }



    subList = () => {
        if (this.state.purchasedSubscriptions && Object.keys(this.state.purchasedSubscriptions).length === 0) {
            return <div>You currently have no subscriptions.</div>
        }
        if (this.state.purchasedSubscriptions) {
            return(
                <div className={styles.activeSubContainer}>
                    <span className={styles.mySubHeader}>
                        {"Active Subscriptions"}
                    </span>
                    <br />
                    {Object.keys(this.state.purchasedSubscriptions).map((storeId) => {
                        const storeObj = this.state.purchasedSubscriptions[storeId];

                        if (storeObj && Object.keys(storeObj).length === 0) {
                            // console.log('Error, null storeObj')
                            return null
                        }
                        return ( 
                        <Grid container direction="column" spacing ={3}>
                            <Grid item>
                                <div className={styles.storeContainer}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <div className={styles.storeHeaderContainer}>
                                                <div className={styles.imageContainer}>
                                                    <img src={"./Hanz 1.png"} alt={''} />
                                                </div>  
                                                <span className={styles.offerMainText}>
                                                    {storeObj[Object.keys(storeObj)[0]]["storeName"]}
                                                </span>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className={styles.offerContainer}>
                                                {Object.keys(this.state.purchasedSubscriptions[storeId]).map((offerId) => {
                                                    const subObj = this.state.purchasedSubscriptions[storeId][offerId];
                                                    return (  
                                                        <div>
                                                            <span className={styles.offerSecondaryText}>
                                                                {"" + subObj["offerName"]}
                                                            </span>
                                                            <span className={styles.offerPricingText}>
                                                                {"$1/mo"}
                                                            </span>
                                                        </div>
                                                       
                                                    )
                                                    
                                                })}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <br />
                                        </Grid>
                                    </Grid>
                                </div> 
                            </Grid> 
                            <Grid item></Grid>
                        </Grid>
                        )
                    })}
                </div>
            )
        } else {
            return <div>No purchased subscriptions for this account.</div>
        }
    }

    render() {

        return (
            <div className={appStyles.appContainer}>
                <Sidebar />
                <div className={appStyles.app}>
                    <div className={listingStyles.headerContainer}>
                        <div className={listingStyles.headerFirstLine}>
                            <Button href={"../"} className={listingStyles.backButton}> Back</Button>
                        </div>
                        <h1 className={listingStyles.headerText}>My Subscriptions</h1>
                        <h1 className={listingStyles.headerSubtext}>Placeholder blurb</h1>
                    </div>

                    { localStorage.getItem("userId") === null ? 
                        <div> User must be signed in to access My Subscriptions. </div>
                        : 
                        <div>
                            

                            <div className={styles.tab}>
                                { this.subList() }
                            </div>
                        </div>
                    }
                </div>
            </div>
            
        );
    }
}

