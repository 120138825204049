import firebase from './firebase.js'
import React, {Component} from 'react';
import styles from './Profile.module.css';
import appStyles from './App.module.css';
import listingStyles from './Listing.module.css';
import { Button, TextField } from "@material-ui/core";

import Sidebar from './Sidebar';

export default class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 'details',
            name: '',
            address: '',
            email: '',
            phone: '',
        }
    }

    async componentDidMount() {
        document.body.style = 'background: #F4FAF6;';

        if (localStorage.getItem("userId") == null) {
            // user not logged in
            return;
        }

        const userDataDoc = await firebase
            .firestore()
            .collection('userData')
            .doc(localStorage.getItem("userId"))
            .get();
        this.setState({
            name: userDataDoc.get("name"),
            address: userDataDoc.get("address"),
            email: userDataDoc.get("email"),
            phone: userDataDoc.get("phone")
        });
    }

    updateDetails = async () => {
        
        const userDataDoc = firebase
            .firestore()
            .collection('userData')
            .doc(localStorage.getItem("userId"));
        userDataDoc.update({
            name: this.state.name,
            address: this.state.address,
            email: this.state.email,
            phone: this.state.phone
        })
    }

    details = () => {
        return (
            <form className={styles.createForm}>
                <TextField value={this.state.name}
                            onChange={(e) => this.setState({name: e.target.value})} 
                            id="outlined-basic"
                            label="Name" variant="outlined"
                            className={styles.createField}/>
                <br/><br/>
                <TextField value={this.state.email}
                            onChange={(e) => this.setState({email: e.target.value})} 
                            id="outlined-basic"
                            label="Email" variant="outlined"
                            className={styles.createField}/>
                <br/><br/>
                <TextField value={this.state.address}
                            onChange={(e) => this.setState({address: e.target.value})} 
                            id="outlined-basic"
                            label="Address" variant="outlined"
                            className={styles.createField}/>
                <br/><br/>
                <TextField value={this.state.phone}
                            onChange={(e) => this.setState({phone: e.target.value})} 
                            id="outlined-basic"
                            label="Phone" variant="outlined"
                            className={styles.createField}/>
                <br/><br/>
                
                <Button variant="contained" onClick={this.updateDetails}>Update</Button>
            </form>
        )
    }

    render() {

        return (
            <div className={appStyles.appContainer}>
                <Sidebar />
                <div className={appStyles.app}>
                    <div className={listingStyles.headerContainer}>
                        <div className={listingStyles.headerFirstLine}>
                            <Button href={"../"} className={listingStyles.backButton}> Back</Button>
                            <h1 className={listingStyles.companyText}>forRegulars</h1>
                        </div>
                        <h1 className={listingStyles.headerText}>Profile</h1>
                    </div>

                    { localStorage.getItem("userId") === null ? 
                        <div> User must be signed in to access Profile. </div>
                        : 
                        <div>
                            <div className={styles.tab}>
                                { this.details() }
                            </div>
                        </div>
                    }
                </div>
            </div>
            
        );
    }
}

