import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'
import LandingPage from './LandingPage';
import Listing from './Listing';
import Profile from './Profile';
import Subscriptions from './Subscriptions';
import Help from './Help';
import Storefront from './Storefront';

function App() {
  return (
      <Router>
        <Switch>
            <Route path="/" exact render={(props) => <LandingPage />} />
            <Route path="/profile" exact render={(props) => <Profile />} />
            <Route path="/subscriptions" exact render={(props) => <Subscriptions />} />
            <Route path="/storefront" exact render={(props) => <Storefront />} />
            <Route path="/listing" render={(props) => <Listing />} />
            <Route path="/help" render={(props) => <Help />} />
        </Switch>
      </Router>
  );
}

export default App;
