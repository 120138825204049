import React, { Component } from 'react';
import Sidebar from "./Sidebar";
import appStyles from './App.module.css';

export default class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
        document.body.style = 'background: #F4FAF6;';
    }


    render() {
        return (
            <div className={appStyles.appContainer}>
                <Sidebar />
                <div className={appStyles.app}>
                    <iframe
                        style={{margin: "auto", display: "block", marginTop: "20px"}}
                        title="Help Form"
                        src="https://docs.google.com/forms/d/e/1FAIpQLSfj0SriI1P8q4e33LKC3a7BqBN6VH-oYuELKU22HwF1JfkgUQ/viewform?embedded=true"
                        width="640" height="378" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                    </iframe>
                </div>
            </div>
        );
    }
}