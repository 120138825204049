import firebase from './firebase.js'
import React, { Component } from 'react';
import styles from './LandingPage.module.css';
import {
    Button,
    Card,
    CardActionArea,
    IconButton,
    InputBase,
    Paper,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

export default class LandingPage extends Component {
    static signIn() {
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

        firebase.auth().useDeviceLanguage();

        firebase.auth().signInWithPopup(provider).then(async function (result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // let token = result.credential.accessToken;
            // The signed-in user info.
            let user = result.user;
            localStorage.setItem('userId', user.uid);
            localStorage.setItem('userName', user.displayName);

            const userDataDoc = firebase
                .firestore()
                .collection('userData')
                .doc(user.uid);

            const userData = await userDataDoc.get();

            if (!userData.exists) {
                await userDataDoc.set({
                    has_storefront: false,
                    name: user.displayName,
                    email: user.email
                })
            }
            window.location.href = "./";
        }).catch(function (error) {
            // Handle Errors here.
            let errorCode = error.code;
            let errorMessage = error.message;
            let email = error.email;
            let credential = error.credential;
            console.log(errorCode);
            console.log(errorMessage);
            console.log(email);
            console.log(credential);
        });
    }
    static logOut() {
        firebase.auth().signOut();
        localStorage.removeItem('userId');
    }

    constructor(props) {
        super(props);
        this.state = {
            searchBarValue: '',
            validListings: {},
            shopLocal: false,
            localZip: "",
            searched: false,
            finishedLoading: true,
        }

        this.handleChange = this.handleChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.searchButtonClick = this.searchButtonClick.bind(this);
    }

    async componentDidMount() {
        document.body.style = 'background: #F4FAF6;';
        let searchTerm = ""
        let url = window.location.href;
        if (url.includes("?")) {
            url = window.location.href.split('?');
            searchTerm = url[url.length - 1];
        }

        this.setState({searchBarValue: searchTerm}, () => {
            if (searchTerm !== "") {
                this.setState({
                    finishedLoading: false,
                    searched: true,
                })
                this.updateValues()
            }
        })
    }

    async updateValues() {
        if (this.state.shopLocal) {
            await fetch("https://for-regulars.herokuapp.com/get_local_listings?" +
                "query=" + this.state.searchBarValue +
                "&zip_code=" + this.state.localZip
                , {
                    method: "GET",

                }).then(response => response.json())
                .then(async (result) => {
                    await this.setState({
                        validListings: result,
                        finishedLoading: true,
                    })
                });
        } else {
            await fetch("https://for-regulars.herokuapp.com/get_listings?" +
                "query=" + this.state.searchBarValue
                , {
                    method: "GET",

                }).then(response => response.json())
                .then(async (result) => {
                    await this.setState({
                        validListings: result,
                        finishedLoading: true,
                    })
                });
        }
    }
    logOut = () => {
        LandingPage.logOut();
        this.setState({})
    }

    handleChange(e) {
        this.setState({ searchBarValue: e.target.value });
    }

    keyPress(e){
        this.setState({ searchBarValue: e.target.value });
        if(e.keyCode === 13){
            e.preventDefault();
            this.updateValues()
            this.setState({
                searched: true,
                finishedLoading: false,
            })
        }
    }
    searchButtonClick(e) {
        e.preventDefault();
        this.updateValues();
        this.setState({
            searched: true,
            finishedLoading: false,
        })
    }
    handleChangeToggle = () => {
        this.setState({
            shopLocal: !this.state.shopLocal
        })
    }
    handleChangeZip = (e) => {
        this.setState({
            localZip: e.target.value
        })
    }

    render() {
        let loggedIn = localStorage.getItem("userId") !== null

        const hasSearched = this.state.searched;
        const openInNewTab = (url) => {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
          }

        return (
            <div className={styles.pageContainer}>
                { hasSearched && this.state.finishedLoading ?
                    <div> 
                    <div className={styles.headerContainer}>
                        <Grid container spacing={5}>
                            <Grid item>
                                <div className={styles.logoContainer}>
                                    <span className={styles.companyText}>forRegulars</span>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={styles.afterSearchBarDiv}>
                                    <Paper component="form" className={styles.searchBarContainer}>
                                        <InputBase
                                            className={styles.searchBarInput}
                                            placeholder="Search by Company or Product"
                                            value={this.state.searchBarValue}
                                            onKeyDown={this.keyPress}
                                            onChange={this.handleChange}
                                        />
                                        <IconButton type="submit" className={styles.iconButton} aria-label="search" onClick={this.searchButtonClick}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Paper>
                                  </div>
                            </Grid>
                            <Grid item>
                                <div className={styles.buttonContainer}>
                                    <Button className={styles.navButton} href = {"./help"}>Help</Button>
                                    {loggedIn ?
                                        <Button className={styles.navButton} href = {"./profile"}>Profile</Button>
                                        :
                                        <Button variant="raised" onClick={LandingPage.signIn.bind(this)} className={styles.navButton}><img src={"./google.png"} alt={"Google"} width={50} style={{marginRight: "20px"}} />Sign Up / Sign In</Button>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <br/><br/><br/><br/><br/>
                    
                    <div className={styles.resultsContainer}>
                        <Grid container>
                            <Grid item>
                                {Object.keys(this.state.validListings).map((listing) => {
                                let imageUrl =
                                    'https://firebasestorage.googleapis.com/v0/b/subscrybe-8db97.appspot.com/o/listing_images%2F' +
                                    this.state.validListings[listing]['image'] +
                                    '?alt=media';
                                return (
                                    <Card className={styles.listingCard} key={listing}>
                                        <CardActionArea
                                            href={'./listing/' + listing}
                                            className={styles.cardActionArea}
                                        >
                                            <img
                                                className={styles.cardImage}
                                                src={imageUrl}
                                                alt={''}
                                            />
                                            <br/>
                                            <div
                                                className={styles.cardTextContainer}
                                            >
                                                <br/>
                                                <span className={styles.cardSubText}>
                                                 Placeholder
                                                </span>
                                                <br />
                                                <span className={styles.cardMainText}>
                                                {this.state.validListings[listing]['name']}
                                                </span>
                                            </div>
                                        </CardActionArea>
                                    </Card>
                                );
                                })}
                            </Grid>
                        </Grid>
                    </div>
                    </div>
                    :   
                    <div> 
                        <div className={styles.headerContainer}>
                            <Grid container spacing={5}>
                                <Grid item>
                                    <div className={styles.logoContainer}>
                                        <span className={styles.companyText}>forRegulars</span>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={styles.spacingContainer}>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={styles.buttonContainer}>
                                        <Button className={styles.navButton} href = {"./help"}>Help</Button>
                                        {loggedIn ?
                                            <Button className={styles.navButton} href = {"./profile"}>Profile</Button>
                                            :
                                            <Button variant="raised" onClick={LandingPage.signIn.bind(this)} className={styles.navButton}><img src={"./google.png"} alt={"Google"} width={50} style={{marginRight: "20px"}} />Sign Up / Sign In</Button>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <br/><br/><br/><br/><br/><br/><br/>              
                        <div className={styles.searchBarDiv}>
                        <Paper component="form" className={styles.searchBarContainer}>
                            <InputBase
                                className={styles.searchBarInput}
                                placeholder="Search by Company or Product"
                                value={this.state.searchBarValue}
                                onKeyDown={this.keyPress}
                                onChange={this.handleChange}
                            />
                            <IconButton type="submit" className={styles.iconButton} aria-label="search" onClick={this.searchButtonClick}>
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                        </div>

                    <div className={styles.imageContainer}>
                        <img src={"./Hanz 1.png"} alt={''} />
                    </div>      

                    </div>
                }
                {!this.state.finishedLoading ?
                    <CircularProgress size={200} color={"inherit"} className={styles.loadingCircle} />
                :
                   null
                }
                
                {Object.keys(this.state.validListings).length === 0?
                    <span className={styles.missionStatement}>
                       From grassroots startups to established community staples, every type of hustle can grow on forRegulars. In the age<br />
                       of big tech and share holder interests, we support those who operate at the community level - we want to be the backbone<br />
                       of businesses who put their customers first. forRegulars gives people-driven ventures  the tools they need to succeed.<br />
                    </span>
                :
                null
                }
                
                <br></br>
                <div className={styles.socialsContainer}>
                    {/*<span className={styles.socialsText}>Connect With Us</span> */}
                    <Button variant="raised" onClick={() => openInNewTab('https://twitter.com/ForRegulars')} className={styles.socialsButton}><img alt={'twitter'} src={"./twitter.png"} width={60} /></Button>
                    {/* <Button variant="raised" onClick={() => openInNewTab('https://linkedin.com')} className={styles.socialsButton}><img src={"./linkedin.png"} width={60} /></Button> */}
                    <Button variant="raised" onClick={() => openInNewTab('https://www.instagram.com/forregulars/')} className={styles.socialsButton}><img alt={'instagram'} src={"./instagram.png"} width={60} /></Button>
                    <Button variant="raised" onClick={() => openInNewTab('https://facebook.com')} className={styles.socialsButton}><img alt={'facebook'} src={"./facebook.png"} width={60} /></Button>
                    <br /><br></br><br></br><br></br>
                </div>
            </div>
        );
    }
}