import React, {Component} from 'react';
import {Button, Grid} from "@material-ui/core";

import styles from './Offer.module.css';

export default class Offer extends Component {
    
    constructor(props) {
        super(props);

        let imageUrl =
            'https://firebasestorage.googleapis.com/v0/b/subscrybe-8db97.appspot.com/o/listing_images%2F' +
            this.props.data["image"] +
            '?alt=media';

        this.state = {
            name: this.props.data.name,
            description: this.props.data.description,
            imageUrl: imageUrl,
            interval: this.props.data.interval,
            intervalCount: this.props.data.intervalCount,
            price: this.props.data.price,
        }
    }

    deleteOrSub = () => {
        if (this.props.seller) {
            return (
                <button onClick={this.props.deleteOffer}>
                    Delete
                </button>
            )
        } else {
            return (
                    <Button variant="contained" 
                        color={this.props.color} 
                        disabled={this.props.disabled} 
                        onClick={this.props.subscribe}
                    >
                        {this.props.subText}
                    </Button>
            )
        }
    }

    render() {
        return (
            <Grid container spacing={6}>
                <Grid item md={10}>
                    <div className={styles.offerContainer}>
                        <img
                            src={this.state.imageUrl}
                            className={styles.offerImg}
                            alt={''}
                        />
                        <div className={styles.offerTextContainer}>
                            <span className={styles.offerMainText}>
                                {this.state.name}
                            </span>
                            <br/>
                            <span className={styles.offerSecondaryText}>
                                {this.state.description}
                                <br/>
                            </span>
                            <span className={styles.offerPriceText}>
                                ${this.state.price + " "} every {this.state.intervalCount} 
                                {" " + this.state.interval}(s)
                            </span>
                            <br />
                            <div className={styles.buttonContainer}>
                                { this.deleteOrSub() }
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            
        )
    }
}