import React, {Component} from 'react';
import styles from "./Sidebar.module.css";
import {IconButton, InputBase, Paper} from "@material-ui/core";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import StoreIcon from '@material-ui/icons/Store';
import { Link } from "react-router-dom";
import LandingPage from "./LandingPage";

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchBarValue: ""
        }
    }
    async componentDidMount() {

    }
    logOut = () => {
        LandingPage.logOut();
        this.setState({});
    }
    signIn = () => {
        LandingPage.signIn();
    }
    search = (e) => {
        e.preventDefault()
        window.location.href = "../?" + this.state.searchBarValue
    }
    handleChange = (e) => {
        this.setState({ searchBarValue: e.target.value });
    }

    keyPress = (e) => {
        this.setState({ searchBarValue: e.target.value });
        if(e.keyCode === 13){
            e.preventDefault();
            this.search();
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <Drawer
                    className={styles.drawer}
                    variant="permanent"
                    classes={{
                        paper: styles.drawerPaper,
                    }}
                    anchor="left"
                >
                    <h1 className={styles.titleText}>forRegulars</h1>
                    <Divider />
                    <div className={styles.innerContainer}>
                        <Paper component="form" className={styles.searchBarContainer}>
                            <InputBase
                                className={styles.searchBarInput}
                                placeholder="Search"
                                value={this.state.searchBarValue}
                                onKeyDown={this.keyPress}
                                onChange={this.handleChange}
                            />
                            <IconButton type="submit" className={styles.iconButton} aria-label="search" onClick={this.search}>
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    </div>

                    <List>
                        <ListItem button key={"Home"} component={Link} to="/">
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary={"Home"} />
                        </ListItem>
                        <ListItem button key={"Profile"} component={Link} to="../profile">
                            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                            <ListItemText primary={"Profile"} />
                        </ListItem>
                        <ListItem button key={"Subscriptions"} component={Link} to="../subscriptions">
                            <ListItemIcon><ShoppingBasket /></ListItemIcon>
                            <ListItemText primary={"My Subscriptions"} />
                        </ListItem>
                        <ListItem button key={"My Storefront"} component={Link} to="../storefront">
                            <ListItemIcon><StoreIcon /></ListItemIcon>
                            <ListItemText primary={"My Storefront"} />
                        </ListItem>

                        <ListItem button key={"Help"} component={Link} to="../help">
                            <ListItemIcon><ContactSupportIcon /></ListItemIcon>
                            <ListItemText primary={"Help"} />
                        </ListItem>
                        {localStorage.getItem("userId") == null ?
                            <ListItem button key={"Sign In"} onClick={this.signIn}>
                                <ListItemIcon><VpnKeyIcon /></ListItemIcon>
                                <ListItemText primary={"Sign In"} />
                            </ListItem>
                            :
                            <ListItem button key={"Sign Out"} onClick={this.logOut}>
                                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                <ListItemText primary={"Sign Out"} />
                            </ListItem>
                        }
                    </List>
                </Drawer>
            </div>

        );
    }
}
