import React, { Component } from 'react';
import styles from './Listing.module.css';
import appStyles from './App.module.css';
import {Button, CircularProgress} from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import Chat from './Chat'
import Sidebar from "./Sidebar";
import Offer from './Offer';

export default class Listing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            image: '',
            offers: {},
            currentPage: 'offer',
            purchased_subscriptions: {},
            listingId: '',
            sellerId: "",
            offerId: "",
            showLoader: "block",
        }
    }

    async componentDidMount() {
        document.body.style = 'background: #F4FAF6;';
        let url = window.location.href.split('/');
        let listingId = url[url.length - 1].split("?")[0];
        await this.setState({listingId})
        const delay = ms => new Promise(res => setTimeout(res, ms));

        if (listingId !== "") {
            let offers = {}
            let listing_data = {}
            let purchased_subscriptions = {}
            if (window.location.href.includes("success")) {
                await delay(3000);
            }
            await fetch("https://for-regulars.herokuapp.com/get_user_subscriptions?" +
                "user_id=" + localStorage.getItem("userId") +
                "&listing_id=" + listingId
                , {
                    method: "GET",
                })
                .then(response => response.json())
                .then(result => {
                    purchased_subscriptions = result
                })

            await fetch("https://for-regulars.herokuapp.com/get_listing_offers?listing_id=" + listingId, {method: "GET"})
            .then(response => response.json())
            .then(result => {
                offers = result;
            })

            await fetch("https://for-regulars.herokuapp.com/get_listing_data?listing_id=" + listingId, {method: "GET"})
            .then(response => response.json())
            .then(result => {
                listing_data = result;
            })

            await this.setState({
                offers,
                listingId,
                purchased_subscriptions,
                sellerId: listing_data["seller_id"],
                name: listing_data["name"],
                image: listing_data["image"],
                stripe_account_id: listing_data["stripe_account_id"],
                showLoader: "none",
            })
        } else {
            window.location.href = "../"
        }
    }

    offerClicked = () => {
        this.setState({currentPage: 'offer'})
    }
    chatClicked = () => {
        this.setState({currentPage: 'chat'})
    }
    subscribe = async (id) => {
        this.setState({offerId: id}, async () => {
            if (this.state.purchased_subscriptions[id]) {
                await this.handleUnsubscribe()
            } else {
                await this.handleCheckout()
            }
        })
    }
    handleCheckout = async () => {
        const stripePromise = loadStripe("pk_live_5reK59NstfonSCVyEsh8s1rk", { stripeAccount: this.state.stripe_account_id});
        const stripe = await stripePromise;

        await fetch("https://for-regulars.herokuapp.com/create-checkout-session?" +
            "price=" + parseFloat(this.state.offers[this.state.offerId]["price"]) * 100 +
            "&name=" + this.state.offers[this.state.offerId]["name"] +
            "&user_id=" + localStorage.getItem("userId") +
            "&store_name=" + this.state.name +
            "&offer_name=" + this.state.offers[this.state.offerId]["name"] +
            "&listing_id=" + this.state.listingId +
            "&offer_id=" + this.state.offerId +
            "&description=" + this.state.offers[this.state.offerId]["description"] +
            "&stripe_account_id=" + this.state.stripe_account_id +
            "&interval=" + this.state.offers[this.state.offerId]["interval"] +
            "&image_url=" + this.state.offers[this.state.offerId]["image"] +
            "&interval_count=" + this.state.offers[this.state.offerId]["intervalCount"]
            , {
            method: "POST",
        }).then((response) => {
            return response.json();
        }).then((session) => {
            return stripe.redirectToCheckout({
                sessionId: session.id,
            });
        }).then((result) => {
            if (result.error) {
                alert(result.error.message);
            }
        });
    };

    handleUnsubscribe = async () => {
        await fetch("https://for-regulars.herokuapp.com/cancel?" +
            "user_id=" + localStorage.getItem("userId") +
            "&listing_id=" + this.state.listingId +
            "&stripe_account_id=" + this.state.stripe_account_id +
            "&offer_id=" + this.state.offerId
            , {
                method: "GET",

        }).then(response => response.json())
        .then(async (result) => {
            alert(result["response"])
            await this.setState({
                purchased_subscriptions: result["data"]
            })
        });
    }


    render() {
        let offerColor = '#b1edbe';
        let chatColor = '#b1edbe';
        if (this.state.currentPage === "offer") {
            offerColor = "#97d8ae"
        } else if (this.state.currentPage === "chat") {
            chatColor = "#97d8ae"
        }
        return (
            <div className={appStyles.appContainer}>
                <Sidebar />
                <div className={appStyles.app}>
                    <div className={styles.headerContainer}>
                        <div className={styles.headerFirstLine}>
                            <Button href={"../"} className={styles.backButton}>Back</Button>
                            <h1 className={styles.companyText}>forRegulars</h1>
                        </div>
                        <h1 className={styles.headerText}>{this.state.name}</h1>
                    </div>
                    <div className={styles.options}>
                        <div className={styles.innerOption} style={{backgroundColor: offerColor}} onClick={this.offerClicked}>
                            <span className={styles.optionText}>Offers</span>
                        </div>
                        <div className={styles.innerOption} style={{backgroundColor: chatColor}} onClick={this.chatClicked}>
                            <span className={styles.optionText}>Chat with Seller</span>
                        </div>
                    </div>

                    <br />
                    <CircularProgress style={{display:this.state.showLoader, margin: "auto"}}/>
                    {this.state.currentPage === "offer" ?
                        Object.keys(this.state.offers).map((id) => {
                            let subText = "Subscribe"
                            let color = "primary"
                            if (this.state.purchased_subscriptions[id]) {
                                subText = "Unsubscribe"
                                color = "secondary"
                            }
                            let disabled = false
                            if (!localStorage.getItem("userId")) {
                                disabled = true
                            }

                            return (
                                <Offer
                                    seller = {false}
                                    data = {this.state.offers[id]}
                                    disabled = {disabled}
                                    subText = {subText}
                                    color = {color}
                                    subscribe = {() => this.subscribe(id)}
                                />
                            );
                        })
                        :
                        null
                    }
                    {this.state.currentPage === "chat" ?
                        <Chat sellerId={this.state.sellerId} sender={"buyer"} />
                        :
                        null
                    }
                    <br /><br />
                </div>
            </div>
        );
    }
}